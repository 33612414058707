<template>
  <div class="inner-section">
      <card>
          <template v-slot:searchHeaderTitle>
            <h4 class="card-title">{{ $t('externalTraining.trainee_evaluation') }} {{ $t('globalTrans.search') }}</h4>
          </template>
          <template v-slot:searchBody>
            <b-row>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Fiscal Year" vid="fiscal_year" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="fiscal_year"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalYearList"
                          id="fiscal_year"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="search.circular_memo_no"
                          :options="circularList"
                          id="circular_memo_no"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                          plain
                          v-model="search.org_id"
                          :options="orgList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          disabled
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_type_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.training_type_id"
                      :options="trainingTypeList"
                      id="training_type_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      disabled
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                    <ValidationProvider name="Batch" vid="batch_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="batch_id"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.batch')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.batch_id"
                          :options="batchList"
                          id="batch_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_title_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="search.training_title_id"
                        :options="trainingTitleList"
                        id="training_title_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        disabled
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col sm="4">
                  <b-button size="sm" variant="primary" @click="searchData">
                    <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                  </b-button>
                </b-col>
              </b-row>
              <!-- <b-row>
                  <b-col lg="6" sm="6">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="circular_memo_no"
                    >
                    <template v-slot:label>
                      {{$t('elearning_iabm.circular_memo_no')}}
                    </template>
                    <b-form-input
                      id="circular_memo_no"
                      v-model.lazy="search.circular_memo_no"
                      :placeholder="$t('elearning_iabm.circular_memo_no')"
                    ></b-form-input>
                  </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="fiscal_year_id"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.fiscal_year')}}
                      </template>
                      <v-select name="organization"
                        v-model="search.fiscal_year_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= fiscalYearList
                        :placeholder="$t('globalTrans.select')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('elearning_config.organization')"
                        label-for="organization"
                    >
                      <v-select name="organization"
                          v-model="search.org_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= orgList
                          :placeholder="$t('globalTrans.select')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_type_id"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_type')}}
                      </template>
                      <b-form-select
                        plain
                        v-model="search.training_type_id"
                        :options="trainingTypeList"
                        id="training_type_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="batch_id"
                      >
                        <template v-slot:label>
                          {{$t('externalTraining.batch')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.batch_id"
                          :options="batchList"
                          id="batch_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_title_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_title')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_title_id"
                          :options="trainingTitleList"
                          id="training_title_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-button size="sm" type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
              </b-row> -->
          </template>
      </card>
      <body-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('externalTraining.trainee_evaluation') }} {{ $t('globalTrans.list') }}</h4>
        </template>
        <template v-slot:headerAction>
          <router-link  class="btn-add" to="trainee-evaluation-form"><i class="ri-add-fill"></i>{{ $t('globalTrans.add_new') }}</router-link>
        </template>
        <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col sm="12">
                  <div class="quick-filter-wrapper">
                      <div class="quick-filter-left">
                      <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                          <b-dropdown-form>
                          <div
                              class="form-group"
                              v-for="(field, index) in labelData"
                              :key="index"
                              >
                              <b-form-checkbox
                                  :id="'checkbox-' + field.label"
                                  v-model="field.show"
                                  :name="'checkbox-' + field.label"
                                  value=1
                                  unchecked-value=0
                              >
                                  {{ ($i18n.locale==='bn') ? field.label : field.label }}
                              </b-form-checkbox>
                          </div>
                          </b-dropdown-form>
                      </b-dropdown>
                      </div>
                      <div class="quick-filter-right">
                      <b-form-group
                          :label="$t('menu.perpage')"
                          label-for="per-page-select"
                      >
                          <b-form-select
                          id="per-page-select"
                          v-model="search.limit"
                          :options="pageOptions"
                          size="sm"
                          ></b-form-select>
                      </b-form-group>
                      </div>
                  </div>
                  </b-col>
              </b-row>
                <b-row>
                    <b-col md="12" class="table-responsive">
                      <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                        <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" thead-class="table_head" striped bordered hover :items="listData" :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                            <template v-slot:cell(serial)="data">
                            {{ $n(data.item.serial + pagination.slOffset) }}
                            </template>
                            <template v-slot:cell(type_name)="data">
                            <span class="capitalize">{{ data.item.type_name }}</span>
                            </template>
                            <template v-slot:cell(status)="data">
                              <span class="badge badge-info" v-if="data.item.status == 1">{{$t('globalTrans.save')}}</span>
                              <span class="badge badge-success" v-else>{{$t('globalTrans.finalSave')}}</span>
                            </template>
                            <!-- <template v-slot:cell(action)="data">
                                <router-link :to="`trainee-evaluation-details?circilarMemoNo=${data.item.circular_memo_no}`" :class="'btn btn-sm btn-secondary text-light'"><i class="fas fa-eye"></i></router-link>
                                <router-link v-if="data.item.status == 1" title="Edit" :to="`/training-e-learning-service/tpm/course-module-form?id=${data.item.id}`" class=" btn btn-sm btn-warning"><i class="ri-ball-pen-fill m-0"></i></router-link>
                            </template> -->
                            <template v-slot:cell(action)="data">
                                <!-- <b-button variant="btn btn-sm btn-success" v-if="data.item.status == 1" size="sm" @click="finalSave(data.item)" ><i class="far fa-check-square m-0"></i></b-button> -->
                                <b-button class="action-btn active view" variant=" iq-bg-success mr-1" :title="$t('globalTrans.view')"  v-b-modal.modal-4 @click="modalData(data.item)"><i class="ri-eye-fill"></i></b-button>
                            </template>
                        </b-table>
                      </slot>
                      <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                        <b-pagination
                          v-model="pagination.currentPage"
                          :perPage="search.limit"
                          :total-rows="pagination.totalRows"
                          @input="searchData"
                        />
                      </div>
                    </b-col>
                </b-row>
            </b-overlay>
        </template>
      </body-card>
      <!-- <b-modal id="modal-4" size="xl" hide-footer :title="$t('elearning_tpm.course_managment') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <div class="float-right">
          <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
            {{  $t('globalTrans.export_pdf') }}
          </b-button>
        </div>
        <div style="clear:both"></div>
        <Details :datas="listToModalData" :key="editItemId"  ref="details"/>
      </b-modal> -->
      <b-modal id="modal-4" size="xl" hide-footer :title="$t('externalTraining.trainee_evaluation')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <!-- <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
          {{  $t('globalTrans.export_pdf') }}
        </b-button> -->
        <Details :datas="listToModalData"  ref="details"/>
      </b-modal>
  </div>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { TrainerSpecificTrainingApplicationList, trainingApplicationTrainerAllTraineeBatch, circularPublishedList, circularData } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Details from './Details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
        return {
          sortBy: '',
          sortDirection: '',
          sortDesc: '',
          // search: {
          //   org_id: [],
          //   circular_memo_no: '',
          //   training_type_id: 0,
          //   training_title_id: 0,
          //   batch_id: 0,
          //   sort_status: 2,
          //   registration_for: 1,
          //   fiscal_year_id: [],
          //   limit: 20
          // },
          search: {
            org_id: 0,
            circular_memo_no: 0,
            training_type_id: 0,
            training_title_id: 0,
            batch_id: 0,
            sort_status: 2,
            registration_for: 1,
            fiscal_year_id: 0,
            limit: 20
          },
          circularList: [],
          listToModalData: [],
          trainingTitleList: [],
          batchListIds: [],
          batchList: [], // based on organization dependency
          labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '7%' } },
          { labels: 'elearning_iabm.circular_memo_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '13%' } },
          { labels: 'elearning_config.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '13%' } },
          { labels: 'elearning_config.training_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '13%' } },
          { labels: 'externalTraining.batch', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '10%' } },
          { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '7%' } }
        ]
      }
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      orgList: function () {
        return this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.status === 1).map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
            } else {
              return { value: item.value, text: item.text_en }
            }
        })
      },
      trainingTypeList: function () {
        return this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTypeList.filter(item => item.status === 1).map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
            } else {
              return { value: item.value, text: item.text_en }
            }
        })
      },
    //  batchList () {
    //     return this.$store.state.ExternalUserService.trainingPanel.commonObj.batchList.filter(item => this.batchListIds.includes(item.value)).map(item => {
    //         if (this.$i18n.locale === 'bn') {
    //           return { value: item.value, text: item.text_bn }
    //         } else {
    //           return { value: item.value, text: item.text_en }
    //         }
    //     })
    //   },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
                { key: 'serial' },
                { key: 'circular_memo_no' },
                { key: 'org_bn' },
                { key: 'training_title_bn' },
                { key: 'batch_bn' },
                { key: 'action' }
              ]
          } else {
              keys = [
                { key: 'serial' },
                { key: 'circular_memo_no' },
                { key: 'org' },
                { key: 'training_title' },
                { key: 'batch' },
                { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      },
      pageOptions () {
        return this.$store.state.commonObj.pageOptions
      },
      currentLocale () {
        return this.$i18n.locale
      }
    },
    // async created () {
    //     this.createTrainerTraineeBatchList()
    //     this.search = Object.assign({}, this.search, {
    //       org_id: this.$store.state.dataFilters.orgId,
    //       fiscal_year_id: this.$store.state.currentFiscalYearId
    //     })
    //     this.loadData()
    // },
    async created () {
      this.getCircularList()
        this.search = Object.assign({}, this.search, {
            // org_id: this.$store.state.dataFilters.orgId,
            fiscal_year_id: this.$store.state.currentFiscalYearId
        })
        this.loadData()
    },
    // watch: {
    //   'search.training_type_id': function (newValue) {
    //     this.trainingTitleList = this.getTrainingTitleList(newValue)
    //   },
    //   'search.org_id': function (newValue) { // Based on organization dependency
    //     this.batchList = this.getBatchList(newValue)
    //   },
    //   currentLocale: function () {
    //     if (this.search.training_type_id) {
    //       this.trainingTitleList = this.getTrainingTitleList(this.search.training_type_id)
    //     }
    //     if (this.search.org_id) { // Based on organization dependency
    //       this.batchList = this.getBatchList(this.search.org_id)
    //     }
    //   }
    // },
    watch: {
      'search.org_id': function (newValue) {
        this.batchList = this.getBatchList(newValue)
      },
      'search.fiscal_year_id': function (newValue) {
        this.getCircularList()
      },
      'search.circular_memo_no': function (newValue) {
        this.getCircularMemoNo(newValue)
      },
      'search.training_type_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    methods: {
      async getCircularMemoNo (getCircularMemoNo) {
      this.load = true
      const params = {
        circular_memo_no: getCircularMemoNo,
        table: 'iab_circular_publications'
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularData, params)
      if (!result.success) {
        this.search.org_id = 0
        this.search.training_title_id = 0
        this.search.training_type_id = 0
      } else {
        this.lcErrorMsg = ''
        const trData = result.data
        this.search.org_id = trData.org_id
        this.search.training_title_id = trData.training_title_id
        this.search.training_type_id = trData.training_type_id
        this.load = false
      }
      this.load = false
    },
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublishedList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          const listObject = result.data
          const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            } else {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            }
          })
          this.circularList = tmpList
        }
        this.circularLoading = false
      },
      getBatchList (orgId) {
        const batchList = this.$store.state.TrainingElearning.commonObj.batchList.filter(item => item.status === 1)
        if (orgId) {
          return batchList.filter(item => item.org_id === orgId)
        }
        return batchList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_type_id === categoryId)
        }
        return trainingTitleList
      },
      async createTrainerTraineeBatchList () {
        const trainerId = this.$store.state.ExternalUserService.trainingPanel.trainerOrTrainee.id
        const params = Object.assign({}, { trainerId: trainerId })
        RestApi.getData(trainingElearningServiceBaseUrl, trainingApplicationTrainerAllTraineeBatch, params).then(response => {
          if (response.success) {
            if (response.trainer_batch_ids !== undefined) {
              this.batchListIds = response.trainer_batch_ids // return unique values from array
            }
          }
        })
      },
      async searchData () {
          this.loadData()
      },
      remove (item) {
        //   this.changeStatus(trainingElearningServiceBaseUrl, courseManagmentToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
      },
      loadData () {
        // this.search.registration_for = this.$store.state.ExternalUserService.trainingPanel.panel
        this.search.trainee_for = this.$store.state.ExternalUserService.trainingPanel.trainerOrTrainee.id
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, TrainerSpecificTrainingApplicationList, params).then(response => {
          if (response.success) {
            // const collectedDatas = [...new Set(response.data.data.filter(circularMemo => response.trainer_circular_memo_nos.includes(circularMemo.circular_memo_no)))]
            this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
            this.paginationData(response.data, this.search.limit)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      getCustomDataList (data) {
        const listData = data.map((item, index) => {
          const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }
          const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTypeObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingTitleObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const batchObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.batchList.find(doc => doc.value === parseInt(item.batch_id))
          const batchData = {}
          if (typeof batchObj !== 'undefined') {
            batchData.batch = batchObj.text_en
            batchData.batch_bn = batchObj.text_bn
          } else {
            batchData.batch = ''
            batchData.batch_bn = ''
          }
          return Object.assign({}, item, { serial: index }, orgData, fiscalYearData, trainingTypeData, trainingTitleData, batchData)
        })
        return listData
      },
      modalData (datas) {
        this.listToModalData = datas
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>
